import { Flex } from "rebass/styled-components";
import { rgba } from "polished";
import Spinner from "./spinner.svg";

interface Props {
  [x: string]: any;
}

const Loader = (props: Props) => {
  const { ratio, opacity, sx, ...rest } = props;

  return (
    <Flex
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: rgba(255, 255, 255, opacity),
        alignItems: "center",
        justifyContent: "center",
        ...sx
      }}
      {...rest}
    >
      <Spinner color={"inuOrange"} fontSize={64} />
    </Flex>
  );
};

Loader.defaultProps = {
  ratio: "20",
  opacity: 0.8
};

export default Loader;
