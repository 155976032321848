import { Flex, Box, Text } from "rebass/styled-components";
import Trans from "next-translate/Trans";

const Separator = () => (
  <Flex alignItems="center" width={1} mb={2}>
    <Box flexGrow={1}>
      <Box
        as="hr"
        mt={"3px"}
        sx={{ border: "0.5px solid", borderColor: "gray" }}
      ></Box>
    </Box>
    <Box flexGrow={0} mx={1}>
      <Text as="span">
        <Trans i18nKey="common:or"/>
      </Text>
    </Box>
    <Box flexGrow={1}>
      <Box
        as="hr"
        mt={"3px"}
        sx={{ border: "0.5px solid", borderColor: "gray" }}
      ></Box>
    </Box>
  </Flex>
);
export default Separator;
